<template>
  <div>
    <div class="toefl-home">
      <div class="page page0">
        <div class="container">
          <div class="row">
            <div class="col-sm-6 left-title">
              <div class="title">
                <h1>
                  FREE Ap ® Mocks.
                  <br />
                  Start practicing now.
                </h1>
                <p class="title-content">
                  Try a full Ap ® practice test. See your scores before test
                  day.
                </p>
              </div>
              <div>
                <div v-if="isLogin">
                  <div class="toefl-button">
                    <router-link :to="{ name: 'SATList' }">
                      <el-button type="success" style="width:100%">
                        SAT Mocks
                      </el-button>
                    </router-link>
                  </div>
                </div>
                <div v-else>
                  <div class="toefl-button" style="margin-bottom:20px">
                    <a href="/login">
                      <el-button type="primary" style="width:100%">
                        Login
                      </el-button>
                    </a>
                  </div>
                  <div class="toefl-button">
                    <a href="/register">
                      <el-button style="width:100%">Register</el-button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-6 right-title">
              <div class="test-img">
                <img
                  style="display:block;width:100%"
                  src="https://testadmit-sat.s3.ap-northeast-1.amazonaws.com/images/SAT.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- page1 -->
      <div class="page page1 text-center">
        <div class="col-md-12 col-sm-12 col-xs-12">
          <div class="page-title" style="margin: 0">
            Made for students around the world
          </div>
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12"></div>
      </div>
      <!-- page2 -->
      <div class="page page2">
        <div class="container">
          <div class="row">
            <div
              class="col-md-6 col-xs-12"
              style="display:flex;align-items:center"
            >
              <div>
                <div class="page-title" v-html="$t('home.page4-title')"></div>
                <div
                  class="page-content"
                  v-html="$t('home.page4-content1')"
                ></div>
              </div>
            </div>
            <div class="col-md-6 col-xs-12">
              <div class="img">
                <img
                  src="https://testadmit-sat.s3.ap-northeast-1.amazonaws.com/images/HomeSATPC.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- page2 -->
      <!-- <div class="page page2">
        <div class="container">
          <div class="row">
            <div class="col-sm-4">
              <div class="data-page">
                <div>

                </div>
                <div class="text-center">
                  <span class="number">+100</span>
                  <span class="number-small">POINTS</span>
                </div>
                <div class="text-center">
                  TestGlider users improved 100 points on average in 1 month.
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="data-page">
                <div>
                </div>
                <div class="text-center">
                  <span class="number">2,768,667</span>
                  <span class="number-small">USERS</span>
                </div>
                <div class="text-darkGray text-center">
                  As of Nov. 2022, 2,768,667 users from 202 countries are using Ap ® Mocks.
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="data-page">
                <div>
                </div>
                <div class="text-center">
                  <span class="number">10000+</span>
                  <span class="number-small">QUESTIONS</span>
                </div>
                <div class="text-darkGray text-center">
                  10000+ questions of SAT practice material to improve your score.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- page3 -->
      <template v-if="false">
        <!-- page1 -->
        <div class="row page page1 text-center">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="page-title" v-html="$t('home.page1-title')"></div>
            <div class="page-content" v-html="$t('home.page1-content1')"></div>
          </div>
          <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="img">
              <img
                src="https://ivy-way-toefl.s3.ap-northeast-1.amazonaws.com/toefl/img/home/page1-TestAdmit.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <!-- page2 -->
        <div class="row page page2" >
          <div class="col-md-6 col-xs-12">
            <div class="page-title" v-html="$t('home.page2-title')"></div>
            <div class="page-content" v-html="$t('home.page2-content1')"></div>
          </div>
          <div class="col-md-6 col-xs-12">
            <div class="img">
              <img
                v-if="lang.value === 'EN-US'"
                src="https://ivy-way-toefl.s3.ap-northeast-1.amazonaws.com/toefl/img/home/page2-TestAdmit.png"
                alt=""
              />
              <img
                v-else
                src="https://ivy-way-toefl.s3.ap-northeast-1.amazonaws.com/toefl/img/home/page2-TestAdmit.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <!-- page4 -->
        <div class="row page page4">
          <div class="col-md-6 col-xs-12">
            <div class="page-title" v-html="$t('home.page4-title')"></div>
            <div class="page-content" v-html="$t('home.page4-content1')"></div>
          </div>
          <div class="col-md-6 col-xs-12">
            <div class="img">
              <img
                src="https://ivy-way-toefl.s3.ap-northeast-1.amazonaws.com/toefl/img/home/page4-TestAdmit.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <!-- page5 -->
        <div class="row page page5">
          <div class="col-md-6 col-xs-12">
            <div class="page-title" v-html="$t('home.page5-title')"></div>
            <div class="page-content" v-html="$t('home.page5-content1')"></div>
          </div>
          <div class="col-md-6 col-xs-12">
            <div class="img">
              <img
                src="https://ivy-way-toefl.s3.ap-northeast-1.amazonaws.com/toefl/img/home/page5.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </template>
      <section class="page3" v-if="false">
        <CourseCard />
      </section>
      <div class="page page3" v-if="false">
        <div class="">
          <div class="text-center">
            <div class="page-title">
              Choose the best plan for you
            </div>
            <div class="page-content">
              Join the 2,700,000+ students currently studying with Ap ® Mocks.
            </div>
          </div>
          <div class="plans">
            <div class="plan-card">
              <div class="plan-title">
                {{ $t("SATPlans.Free Pass") }}
              </div>
              <div class="plan-sale" style="height:32px">
                <!-- <span class="sale-price">$00.00</span>
                <span class="sale">25% OFF</span> -->
              </div>
              <div class="price">
                <span class="price-number">$0</span>
                <span class="price-last">USD</span>
              </div>
              <div class="buy-button mt-3 mb-3">
                <router-link :to="{ name: 'SATList' }">
                  <el-button type="success" style="width: 100%">
                    {{ $t("SATPlans.START NOW") }}
                  </el-button>
                </router-link>
              </div>
              <div class="plan-list">
                <p>
                  <i class="fas fa-check-circle"></i>
                  {{ $t("SATPlans.Access on Any Device") }}
                </p>
                <p>
                  <i class="fas fa-check-circle"></i>
                  {{ $t("SATPlans.Practice Anytime") }}
                </p>
                <p>
                  <i class="fas fa-check-circle"></i>
                  {{ $t("SATPlans.Immediate Grading") }}
                </p>
                <p>
                  <i class="fas fa-check-circle"></i>
                  {{ $t("SATPlans.Explanation for All Questions") }}
                </p>
                <p>
                  <i class="fas fa-check-circle"></i>
                  {{ $t("SATPlans.Skill Analysis") }}
                </p>
                <p>
                  <i class="fas fa-check-circle"></i>
                  {{ $t("SATPlans.SAT Flashcards") }}
                </p>
                <p>
                  <i class="fas fa-check-circle"></i>
                  {{ $t("SATPlans.Non-Linear (Adaptive) Test:") }}
                  {{ $t("SATPlans.Coming Soon") }}
                </p>
                <p>
                  <i class="fas fa-check-circle"></i>
                  {{ $t("SATPlans.CB Questions:") }} 240
                </p>
                <p>
                  <span class="text-danger"
                    ><i class="fas fa-times-circle"></i
                  ></span>
                  {{ $t("SATPlans.Original Questions:") }} 240+
                </p>
                <p>
                  <span class="text-danger"
                    ><i class="fas fa-times-circle"></i
                  ></span>
                  {{ $t("SATPlans.3 tests per day") }}
                </p>
                <p>
                  <span class="text-danger"
                    ><i class="fas fa-times-circle"></i
                  ></span>
                  {{ $t("SATPlans.Custom Vocabulary Lists") }}
                </p>
                <p>
                  <span class="text-danger"
                    ><i class="fas fa-times-circle"></i
                  ></span>
                  {{ $t("SATPlans.Ads-Free") }}
                </p>
                <p>
                  <span class="text-danger"
                    ><i class="fas fa-times-circle"></i
                  ></span>
                  {{ $t("SATPlans.Free Study Materials") }}
                </p>
              </div>
            </div>
            <div class="plan-card">
              <div class="plan-title">
                {{ $t("SATPlans.Pro Pass") }}
              </div>
              <div class="plan-sale">
                <span class="sale-price">$299</span>
                <span class="sale">67% OFF</span>
              </div>
              <div class="price">
                <span class="price-number">$99</span>
                <span class="price-last">USD / 12 MONTHS</span>
              </div>
              <div class="buy-button mt-3 mb-3">
                <!-- <router-link :to="{ name: 'Plans' }">
                  <el-button type="success" style="width: 100%">
                    {{ $t('SATPlans.BUY NOW') }}
                  </el-button>
                </router-link> -->
                <el-button
                  type="success"
                  style="width: 100%"
                  @click="getPro('month', 1)"
                >
                  {{ $t("SATPlans.BUY NOW") }}
                </el-button>
              </div>
              <div class="plan-list">
                <p>
                  <i class="fas fa-check-circle"></i>
                  {{ $t("SATPlans.Access on Any Device") }}
                </p>
                <p>
                  <i class="fas fa-check-circle"></i>
                  {{ $t("SATPlans.Practice Anytime") }}
                </p>
                <p>
                  <i class="fas fa-check-circle"></i>
                  {{ $t("SATPlans.Immediate Grading") }}
                </p>
                <p>
                  <i class="fas fa-check-circle"></i>
                  {{ $t("SATPlans.Explanation for All Questions") }}
                </p>
                <p>
                  <i class="fas fa-check-circle"></i>
                  {{ $t("SATPlans.Skill Analysis") }}
                </p>
                <p>
                  <i class="fas fa-check-circle"></i>
                  {{ $t("SATPlans.SAT Flashcards") }}
                </p>
                <p>
                  <i class="fas fa-check-circle"></i>
                  {{ $t("SATPlans.Non-Linear (Adaptive) Test:") }}
                  {{ $t("SATPlans.Coming Soon") }}
                </p>
                <p>
                  <i class="fas fa-check-circle"></i>
                  {{ $t("SATPlans.CB Questions:") }} 240
                </p>
                <p>
                  <i class="fas fa-check-circle"></i>
                  {{ $t("SATPlans.Original Questions:") }} 240+
                </p>
                <p>
                  <i class="fas fa-check-circle"></i>
                  {{ $t("SATPlans.Unlimited Daily Practices") }}
                </p>
                <p>
                  <i class="fas fa-check-circle"></i>
                  {{ $t("SATPlans.Custom Vocabulary Lists") }}
                </p>
                <p>
                  <i class="fas fa-check-circle"></i>
                  {{ $t("SATPlans.Ads-Free") }}
                </p>
                <p>
                  <i class="fas fa-check-circle"></i>
                  {{ $t("SATPlans.Free Study Materials") }}
                </p>
              </div>
            </div>
            <div class="plan-card">
              <div class="plan-title">
                {{ $t("SATPlans.Pro Pass") }}
              </div>
              <div class="plan-sale">
                <span class="sale-price">$499</span>
                <span class="sale">50% OFF</span>
              </div>
              <div class="price">
                <span class="price-number">$249</span>
                <span class="price-last"
                  >USD / LIFETIME</span
                >
              </div>
              <div class="buy-button mt-3 mb-3">
                <!-- <router-link :to="{ name: 'Plans' }">
                  <el-button type="success" style="width: 100%" >
                    {{ $t('SATPlans.BUY NOW') }}
                  </el-button>
                </router-link> -->
                <el-button
                  type="success"
                  style="width: 100%"
                  @click="getPro('year', 1)"
                >
                  {{ $t("SATPlans.BUY NOW") }}
                </el-button>
              </div>
              <div class="plan-list">
                <p>
                  <i class="fas fa-check-circle"></i>
                  {{ $t("SATPlans.Access on Any Device") }}
                </p>
                <p>
                  <i class="fas fa-check-circle"></i>
                  {{ $t("SATPlans.Practice Anytime") }}
                </p>
                <p>
                  <i class="fas fa-check-circle"></i>
                  {{ $t("SATPlans.Immediate Grading") }}
                </p>
                <p>
                  <i class="fas fa-check-circle"></i>
                  {{ $t("SATPlans.Explanation for All Questions") }}
                </p>
                <p>
                  <i class="fas fa-check-circle"></i>
                  {{ $t("SATPlans.Skill Analysis") }}
                </p>
                <p>
                  <i class="fas fa-check-circle"></i>
                  {{ $t("SATPlans.SAT Flashcards") }}
                </p>
                <p>
                  <i class="fas fa-check-circle"></i>
                  {{ $t("SATPlans.Non-Linear (Adaptive) Test:") }}
                  {{ $t("SATPlans.Coming Soon") }}
                </p>
                <p>
                  <i class="fas fa-check-circle"></i>
                  {{ $t("SATPlans.CB Questions:") }} 240
                </p>
                <p>
                  <i class="fas fa-check-circle"></i>
                  {{ $t("SATPlans.Original Questions:") }} 240+
                </p>
                <p>
                  <i class="fas fa-check-circle"></i>
                  {{ $t("SATPlans.Unlimited Daily Practices") }}
                </p>
                <p>
                  <i class="fas fa-check-circle"></i>
                  {{ $t("SATPlans.Custom Vocabulary Lists") }}
                </p>
                <p>
                  <i class="fas fa-check-circle"></i>
                  {{ $t("SATPlans.Ads-Free") }}
                </p>
                <p>
                  <i class="fas fa-check-circle"></i>
                  {{ $t("SATPlans.Free Study Materials") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- page7 -->
      <div class="page page7" style="height:auto;min-height: auto">
        <div class="row">
          <div class="col-sm-6 col-xs-12">
            <div class="page-title">
              Start Practicing for free.
            </div>
          </div>
          <div class="col-sm-6">
            <div v-if="isLogin">
              <div class="row" style="margin:0;line-height:54px">
                <router-link :to="{ name: 'SATList' }" style="width:100%">
                  <el-button type="primary" style="width:100%">
                    START
                  </el-button>
                </router-link>
              </div>
            </div>
            <div v-else>
              <div class="row" style="margin:0;line-height:54px">
                <div class="toefl-button col-md-6">
                  <a href="/login" style="width:100%">
                    <el-button type="primary" style="width:100%">
                      Login
                    </el-button>
                  </a>
                </div>
                <div class="toefl-button col-md-6">
                  <a href="/register" style="width:100%">
                    <el-button style="width:100%">Register</el-button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section class="page4" v-if="false">
        <Faq />
      </section>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Plans from "@/apis/plans";
import CourseCard from "@/views/header/CourseCard.vue";
import Faq from "@/views/header/Instructors.vue";

export default {
  metaInfo() {
    return {
      title: "Home - " + this.CompanyName
    };
  },
  components: { CourseCard, Faq },
  mixins: [],

  props: [],
  data() {
    return {};
  },
  computed: {
    ...mapState("user", ["token", "lang"]),
    isLogin() {
      return this.token !== "";
    }
  },
  watch: {},

  mounted() {},

  methods: {
    async getPro(interval, intervalCount) {
      this.$router.replace({ name: "Plans" });
    }
  }
};
</script>

<style scoped>
.container {
  padding-top: 0;
}
.toefl-title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}
.test-img {
  padding: 0.3rem;
  box-shadow: rgb(0 0 0 / 14%) 0px 4px 16px;
}
.page-title {
  font-size: 50px;
  line-height: 65px;
  font-weight: 700;
  margin-bottom: 10px;
  width: 100%;
}
.page {
  width: 100%;
}
.page1 .page-title {
  margin-bottom: 20px;
}
.page-content {
  font-size: 1.125rem;
  font-weight: 500;
  margin-bottom: 20px;
}
.page .img {
  text-align: center;
}
.page .img img {
  max-height: 550px;
  max-width: 100%;
}
.page0,
.page4,
.page3,
.page5 {
  background: #fff;
}
.page1,
.page5 {
  background: var(--themeColor);
  color: #fff;
}
.page6 {
  text-align: center;
}
.page6 a {
  display: block;
}
.page6 .img {
  padding: 30px;
}

.page6 .img img {
  width: 100%;
  max-height: 300px;
  border: 4px solid #efefef;
}
.page3,
.page5 {
  flex-direction: row-reverse;
}
.page7 {
  background: #ebebeb;
}
.toefl-welcome {
  /* margin-bottom: 15px; */
}
.toefl-home {
  /* padding-top: 20px; */
}
.toefl-img {
  border: 2px solid var(--themeColor);
  padding: 20px;
}

.left-title {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.right-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.title {
  font-size: 40px;
  line-height: 55px;
  font-weight: 700;
  color: var(--themeColor);
}
.title-content {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 2.25rem;
  letter-spacing: -0.01em;
  display: inline-block;
  color: #212529;
}
.title h1 {
  font-weight: 700;
  color: var(--themeColor);
  margin-bottom: 0;
}
.toefl-button {
  margin: 30px 0 0 0;
  /* padding: 0 20px; */
}
.page .toefl-button {
  margin: auto 0;
  /* padding: 0 20px; */
}
.right {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.data-page .number {
  font-size: 3rem;
  font-weight: 700;
  line-height: 4.5rem;
  letter-spacing: -0.015em;
  display: inline-block;
  color: var(--themeColor);
  display: inline-block;
  margin-right: 0.5rem;
}
.data-page .number-small {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5rem;
  display: inline-block;
}

.plans {
  display: flex;
  justify-content: center;
  margin-top: 60px;
}
.page.page3 {
  padding: 80px 0;
}
.plan-card {
  width: 30%;
  display: flex;
  flex-direction: column;
  color: rgb(17, 74, 198);
  border-radius: 16px;
  box-shadow: rgb(0 0 0 / 14%) 0px 4px 16px;
  overflow: hidden;
  padding: 24px 20px 72px;
  background-repeat: no-repeat;
  background-image: url(https://www.testglider.com/_next/static/media/30dayPassSubBg.798d42dc.webp);
  background-size: cover;
  margin: 0 1rem;
}
.plan-title {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 40px;
  letter-spacing: -0.12px;
}
.sale-price {
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.09px;
  text-decoration-line: line-through;
  margin-right: 0.5rem;
}
.sale {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 1.26px;
  text-transform: uppercase;
  padding: 0px 8px;
  background: rgb(17, 74, 198);
  color: rgb(222, 248, 118);
  border-radius: 4px;
}
.price {
  font-weight: 700;
  font-size: 48px;
  line-height: 72px;
  letter-spacing: -0.96px;
}
.price-last {
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.12px;
  margin-left: 4px;
}
.plan-list {
  min-width: 500px;
}
.plan-list p {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.07px;
  text-shadow: rgb(0 0 0 / 10%) 0px 4px 4px;
  /* margin: 0; */
}
@media screen and (min-width: 1080px) {
  .toefl-container {
    padding: 0;
  }
  .page {
    padding: 80px 10%;
    /* text-align: center; */
    /* min-height: calc(100vh - 60px); */
    border-top: 1px solid #efefef;
  }
  .page0 {
    padding: 80px 60px;
    border: none;
  }
  .page > .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    flex-direction: column;
  }
  .page7 .page-title {
    margin-bottom: 0px;
  }
}
@media screen and (max-width: 1080px) {
  .title {
    font-size: 30px;
    line-height: 30px;
    font-weight: 700;
    color: var(--themeColor);
    text-align: center;
    margin-top: 20px;
  }
  .title h1 {
    font-size: 30px;
  }
  .toefl-container {
    /* padding: 15px; */
  }
  .toefl-button {
    margin: 30px 0 20px 0;
  }
  .title {
    margin-bottom: 0;
  }
  .page {
    padding: 40px 20px;
    border-top: 1px solid #efefef;
  }
  .page-title {
    font-size: 25px;
    line-height: 1.125;
    font-weight: 600;
    text-align: center;
    margin-bottom: 30px;
    word-break: unset !important;
  }
  .page1 .page-title {
    margin-bottom: 30px;
    text-align: center;
  }
  .page2.page .img img {
    width: 80%;
  }
  .page-content {
    font-size: 19px;
    font-weight: 400;
    line-height: 1.3;
    margin-bottom: 20px;
    word-break: unset !important;
  }
  .page .img {
    text-align: center;
    width: 100%;
  }
  .page .img img {
    height: auto;
    width: 100%;
  }

  .page6 {
    text-align: center;
  }
  .page6 a {
    display: block;
  }
  .page6 .img {
    padding: 15px 0;
  }

  .page6 .img img {
    width: 100%;
    max-height: 300px;
    border: 4px solid #efefef;
  }
  .page3,
  .page5 {
    flex-direction: row-reverse;
  }
  .page7 {
    background: #ebebeb;
  }
}
@media screen and (max-width: 768px) {
  .title-content,
  .page-content {
    font-size: 1rem;
    line-height: 1.3;
  }
  .title-content {
    margin-top: 1rem;
  }
  .plans {
    display: block;
    margin-top: 15px;
  }
  .plans .plan-card {
    width: 100%;
    margin: 0;
    margin-bottom: 15px;
  }
  .page {
    padding: 15px 0;
  }
  .page.page3,
  .page.page7 {
    padding: 40px 15px;
  }
  .left-title {
    margin-bottom: 15px;
  }
  .page-title {
    margin-bottom: 10px;
  }
  .page5 .page-title {
    margin-top: 10px;
    text-align: left;
  }
}
@media screen and (max-width: 400px) {
  .page-title {
    margin-bottom: 10px;
  }
  .toefl-button {
    padding: 0;
  }
  .toefl-container {
    padding-bottom: 0;
  }
}
</style>
